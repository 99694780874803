<template>
    <div>
        <div
            v-if="coupons.length > 0"
            class="d-flex align-items-start justify-content-start gap-2"
        >
            <img
                v-if="pointName === 'Loyalty Points'"
                src="@/assets/img/Royalty.png"
                alt="loyalty"
                style="width: 2rem;"
            />
            <img
                v-if="pointName === 'Mining Points'"
                src="@/assets/img/Mining.png"
                alt="loyalty"
                style="width: 2rem;"
            />
            <img
                v-if="pointName === 'Refer Points'"
                src="@/assets/img/Referal1.png"
                alt="loyalty"
                style="width: 2rem;"
            />
            <h4 class="fw-bold">Redeem by {{ pointName }}</h4>
        </div>
        <!-- <div> -->
        <div class="d-flex gap-2 flex-wrap">
            <div v-for="(coupon, index) in coupons" :key="index">
                <div style="width: 14rem; height:130px" class="d-flex">
                    <div class="content" style="width:25%;">
                        <div
                            class="d-flex flex-column align-items-center text-white"
                            style="margin-top:3rem"
                        >
                            <font-awesome-icon
                                v-if="coupon.point_type == 'shwepyae'"
                                :icon="['fas', 'crown']"
                            />
                            <font-awesome-icon
                                v-if="coupon.point_type == 'ngwepyae'"
                                :icon="['far', 'gem']"
                                style="transform: scaleY(-1);"
                            />
                            <div
                                v-if="coupon.point_type == 'referral'"
                                class="octagon"
                            ></div>
                            <span>{{ coupon.point }}</span>
                        </div>
                    </div>
                    <div
                        style="width: 70%;border: 1px solid #0000000D;background:#FFFFFF"
                        class="position-relative"
                    >
                        <div class="d-flex flex-column justify-content-between">
                            <div class="ms-1 pt-1">
                                <!-- <h6 class="fw-bold" style="font-size:12px">{{ coupon.name }}</h6>
                        <small>{{ coupon.description }}</small> -->
                                <p
                                    class="fw-bold"
                                    style="font-size:16px; margin:0; padding:0"
                                >
                                    {{ coupon.price }} MMK
                                </p>
                                <!-- <small style="font-size:11px"
                                    >{{
                                        coupon.description.substring(0, 40)
                                    }}...</small
                                ><br /> -->
                                <small style="font-size:13px;">
                                    expire in
                                    {{ expireDays(coupon.expire_date) }} days
                                </small>
                            </div>
                            <div class="pb-2 ps-5">
                                <!-- <button
                                    v-if="userPoints >= coupon.point"
                                    class="redeem-card-btn"
                                    @click="
                                        showRewardDetail({
                                            id: coupon.id,
                                            img: coupon.image,
                                            title: coupon.name,
                                            points: coupon.point,
                                            desc: coupon.description,
                                        })
                                    "
                                >
                                    Redeem
                                    <span
                                        ><font-awesome-icon
                                            :icon="['fas', 'chevron-right']"
                                    /></span>
                                </button> -->
                                <button
                                    class="redeem-card-btn"
                                    @click="
                                        showRewardDetail({
                                            id: coupon.id,
                                            img: coupon.image,
                                            title: coupon.name,
                                            points: coupon.point,
                                            desc: coupon.description,
                                        })
                                    "
                                >
                                    Redeem
                                    <span
                                        ><font-awesome-icon
                                            :icon="['fas', 'chevron-right']"
                                    /></span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- </div> -->
        <!-- Dialog -->
        <div id="rewardModal">
            <!-- Modal-->
            <transition
                @enter="startTransitionModal"
                @after-enter="endTransitionModal"
                @before-leave="endTransitionModal"
                @after-leave="startTransitionModal"
            >
                <div
                    class="modal fade"
                    style="background: #0000001A;"
                    v-show="isOpenDetailModal"
                    ref="modal"
                    @click.self="isOpenDetailModal = false"
                >
                    <div v-if="isLoggedIn" class="modal-dialog" role="document">
                        <div
                            class="modal-content"
                            :style="
                                dialogHeight
                                    ? 'overflow-y: auto; height: 75vh'
                                    : ''
                            "
                        >
                            <div class="modal-body">
                                <div class="text-center">
                                    <img
                                        :src="detailModalObj.img"
                                        alt="modal image"
                                        style="width: 300px; height:200px;"
                                    />
                                </div>
                                <p class="detail-modal-title mb-1 mt-2 px-3">
                                    {{ detailModalObj.title }}
                                </p>
                                <div class="points mb-2 px-3">
                                    <img
                                        v-if="$route.name == 'ShwePyae'"
                                        width="25"
                                        src="@/assets/img/Royalty.png"
                                        alt="point image"
                                    />
                                    <img
                                        v-else-if="
                                            $route.name == 'ReferralPage'
                                        "
                                        width="15"
                                        src="@/assets/img/Referal1.png"
                                        alt="point image"
                                    />
                                    <img
                                        v-else
                                        width="25"
                                        src="@/assets/img/Mining.png"
                                        alt="point image"
                                    />
                                    {{ detailModalObj.points }}
                                    <span class="fw-lighter">Points</span>
                                </div>
                                <div class="reward-desc-section">
                                    <p class="fw-bold title px-3 m-0">
                                        Reward Description
                                    </p>
                                    <p class="px-3 m-0">
                                        {{ detailModalObj.desc }}
                                    </p>
                                </div>
                                <div class="text-center">
                                    <div class="you-have my-3">
                                        You have
                                        <img
                                            v-if="$route.name == 'ShwePyae'"
                                            width="15"
                                            src="@/assets/img/Royalty.png"
                                            alt="point image"
                                        />
                                        <img
                                            v-else-if="
                                                $route.name == 'ReferralPage'
                                            "
                                            width="15"
                                            src="@/assets/img/Referal1.png"
                                            alt="point image"
                                        />
                                        <img
                                            v-else
                                            width="15"
                                            src="@/assets/img/Mining.png"
                                            alt="point image"
                                        />
                                        {{ userPoints }} Points
                                    </div>
                                    <main-button
                                        v-if="
                                            userPoints >= detailModalObj.points
                                        "
                                        name="Redeem"
                                        @clicked="makeRedeem(detailModalObj.id)"
                                        :class="{ redeemedBtn: isRedeem }"
                                        :disabled="isRedeem"
                                    />
                                    <main-button
                                        v-else
                                        name="Redeem"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="left"
                                        title="You don't have enough coin to redeem this package."
                                    />
                                </div>
                            </div>
                            <div
                                v-if="isRedeem"
                                class="modal-dialog"
                                role="document"
                                id="myRedeem"
                            >
                                <div class="modal-content">
                                    <div class="modal-body">
                                        <template v-if="!isLoading">
                                            <div class="valid-feedback d-block">
                                                <font-awesome-icon
                                                    icon="check-circle"
                                                    class="me-1"
                                                />
                                                <b>Redeem Successful</b>
                                            </div>
                                            <p class="my-2">
                                                Discount Code :
                                                <span class="fw-bold">{{
                                                    code
                                                }}</span>
                                            </p>
                                            <div
                                                class="d-flex justify-content-center mb-3"
                                            >
                                                <button
                                                    class="btn me-3"
                                                    @click="copyCode(code)"
                                                >
                                                    <font-awesome-icon
                                                        :icon="['fas', 'copy']"
                                                        class="me-1"
                                                    />
                                                    Copy Code
                                                </button>
                                                <button
                                                    class="btn"
                                                    @click="captureImage(code)"
                                                >
                                                    <font-awesome-icon
                                                        :icon="[
                                                            'fas',
                                                            'file-download',
                                                        ]"
                                                        class="me-1"
                                                    />
                                                    Save as Photo
                                                </button>
                                            </div>
                                        </template>
                                        <template v-else>
                                            <div class="text-center">
                                                <div
                                                    class="spinner-border"
                                                    style="width: 3rem; height: 3rem"
                                                    role="status"
                                                >
                                                    <span
                                                        class="visually-hidden"
                                                        >Loading...</span
                                                    >
                                                </div>
                                            </div>
                                        </template>
                                    </div>
                                </div>
                            </div>
                            <div
                                @click="isOpenDetailModal = false"
                                class="close-btn"
                            >
                                <span>Close</span>
                            </div>
                        </div>
                    </div>
                    <div v-else class="modal-dialog" role="document">
                        <div
                            class="modal-content"
                            :style="
                                dialogHeight
                                    ? 'overflow-y: auto; height: 75vh'
                                    : ''
                            "
                        >
                            <div class="modal-body text-center fs-3 fw-bold">
                                Redeem
                            </div>
                            <div class="modal-body text-center">
                                Please Login to use Redeem Tickets
                            </div>
                            <div class="text-center">
                                <main-button
                                    name="Login"
                                    @clicked="goToLogin"
                                />
                            </div>
                            <div
                                @click="isOpenDetailModal = false"
                                class="close-btn"
                            >
                                <span>Close</span>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- <div
                    class="modal fade"
                    style="background: #0000001A"
                    v-show="isRedeem"
                    ref="modal"
                    @click.self="isRedeem = false"
                > -->
            </transition>
            <div class="modal-backdrop fade d-none" ref="backdrop"></div>
        </div>
    </div>
</template>

<script>
import Button from '../reusable/Button';
import GenerateRedeemCode from '@/graphql/mutations/point/GenerateRedeemCode.gql';
import { mapGetters } from 'vuex';
import html2canvas from 'html2canvas';

export default {
    props: {
        pointName: {
            type: String,
            default: () => '',
        },
        coupons: {
            type: Array,
            default: () => [],
        },
    },
    components: {
        'main-button': Button,
    },
    computed: {
        ...mapGetters({
            user: 'auth/getUser',
            isLoggedIn: 'auth/isLoggedIn',
        }),
        userPoints() {
            let points = 0;
            switch (this.pointName) {
                case 'Loyalty Points':
                    points = this.user?.product_point;
                    break;
                case 'Mining Points':
                    points = this.user?.point;
                    break;
                case 'Referral Points':
                    points = this.user?.referral_point;
                    break;
            }
            return points;
        },
    },
    data() {
        return {
            detailModalObj: {
                id: null,
                img: null,
                title: null,
                points: null,
                desc: null,
            },
            isOpenDetailModal: false,
            isRedeem: false,
            isLoading: false,
            code: '',
            dialogHeight: false,
        };
    },
    methods: {
        captureImage(code) {
            const element = document.getElementById('myRedeem');
            html2canvas(element).then(canvas => {
                const image = canvas.toDataURL('image/png');
                const link = document.createElement('a');
                link.download = `${code}.png`;
                link.href = image;
                link.click();
            });
        },
        showRewardDetail({ id, img, title, points, desc }) {
            if (this.isLoggedIn) {
                this.detailModalObj = {
                    id,
                    img,
                    title,
                    points,
                    desc,
                };
            }
            // this.detailModalObj = {
            //     id,
            //     img,
            //     title,
            //     points,
            //     desc,
            // };
            this.isOpenDetailModal = true;
            // alert('data');
        },
        makeRedeem(id) {
            this.dialogHeight = true;
            this.isRedeem = true;
            this.isLoading = true;

            this.$apollo
                .mutate({
                    mutation: GenerateRedeemCode,

                    variables: {
                        user_id: this.user.id,
                        redeem_id: id,
                    },
                })
                .then(response => {
                    console.log('redeem making api' + response.data);
                    const redeemCode = response.data.generateRedeemCode;
                    this.code = redeemCode.code;
                    const userObj = {
                        me: {
                            ...redeemCode.user,
                        },
                    };
                    console.log(userObj);
                    this.$store.commit('auth/ADD_USER', userObj);
                    this.isLoading = false;
                    // this.getRedeemHistory();
                    this.$parent.$emit('reloadHistory');
                })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                });
        },

        closeDetailModal() {
            this.detailModalObj = {
                id: null,
                img: null,
                title: null,
                points: null,
                desc: null,
            };
            this.isOpenDetailModal = false;
        },

        copyCode(text) {
            navigator.clipboard.writeText(text);
        },

        // async makePhoto() {
        //     const canvas = document.createElement('canvas');
        //     const context = canvas.getContext('2d');
        //     const video = document.createElement('video');

        //     try {
        //         const captureStream = await navigator.mediaDevices.getDisplayMedia();
        //         video.srcObject = captureStream;
        //         context.drawImage(video, 0, 0, window.width, window.height);
        //         const frame = canvas.toDataURL('image/png');
        //         captureStream.getTracks().forEach(track => track.stop());
        //         window.location.href = frame;
        //     } catch (err) {
        //         console.error('Error: ' + err);
        //     }
        // },
        startTransitionModal() {
            this.$refs.backdrop.classList.toggle('d-block');
            this.$refs.modal.classList.toggle('d-block');
        },
        endTransitionModal() {
            this.$refs.backdrop.classList.toggle('show');
            this.$refs.modal.classList.toggle('show');
        },
        expireDays(date) {
            if (date != null) {
                const expireDate = date.split(' ')[0];
                const expireYear = expireDate.split('-')[0];
                const expireMonth = expireDate.split('-')[1];
                const expireDay = expireDate.split('-')[2];
                let date_1 = new Date(
                    `${expireMonth}/${expireDay}/${expireYear}`,
                );
                let date_2 = new Date();

                const days = (date_1, date_2) => {
                    let difference = date_1.getTime() - date_2.getTime();
                    let TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
                    return TotalDays;
                };
                return days(date_1, date_2);
            } else {
                return '-';
            }
        },
        goToLogin() {
            this.$router.push({
                name: 'Login',
                query: {
                    slug: 'redeem',
                },
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.content {
    width: 65px;
    height: 100%;
    -webkit-mask: radial-gradient(circle at top, transparent 10px, red 0),
        radial-gradient(circle at bottom, transparent 10px, red 0);
    -webkit-mask-composite: source-in;
    background: #e4a825 0% 0% no-repeat padding-box;
}
.redeem-card-btn {
    background: #e4a825 0% 0% no-repeat padding-box;
    border-radius: 18px;
    opacity: 1;
    color: #fff;
    border-style: none;
    position: absolute;
    // left: 41%;
    bottom: 5px;
    right: 5px;
    font-size: 12px;
}
.close-btn {
    background: #f6f6f6 0% 0% no-repeat padding-box;
    border-radius: 18px;
    opacity: 1;
    color: #333;
    cursor: pointer;
    border-style: none;
    margin: 1rem auto;

    // position: absolute;
    // bottom: -2rem;
    font-size: 12px;
    left: 43%;
    font-size: 14px;
    font-weight: 500;
    width: max-content;
    padding: 0.5rem 1rem;
    transition: all 0.5s ease;
    &:hover {
        color: #333;
    }
}
.octagon {
    clip-path: polygon(
        48% 0,
        49% 0,
        100% 30%,
        100% 70%,
        48% 100%,
        48% 100%,
        0% 70%,
        0% 30%
    );
    width: 1rem;
    background: white;
    height: 1.5rem;
}
</style>
