<template>
    <button class="btn" @click="$emit('clicked')">{{ name }}</button>
</template>

<script>
export default {
    props: {
        name: {
            type: String,
            required: true,
        },
    },
};
</script>

<style lang="scss">
@import '@/sass/components/button.scss';
</style>
