<template>
    <div class="container py-5">
        <div class="row position-relative">
            <div class="col-12 col-lg-8 col-xl-9">
                <div
                    class="position-relative d-none d-lg-block"
                    v-if="bannerThree"
                >
                    <img
                        :src="bannerThree.photo.file_url"
                        class="d-block w-100"
                        alt="slide image"
                    />
                    <div class="text position-absolute">
                        <!-- <p class="w-50" style="color: #775A07">
                            {{ p.title }}
                        </p> -->
                        <h1 class="w-50 fw-bolder" style="color: #775A07;">
                            {{ bannerThree.description }}
                        </h1>
                        <main-button
                            name="GET REWARD NOW"
                            style="background-color:#775A07"
                            @clicked="goToPointsPage"
                        />
                    </div>
                </div>
                <div class="row py-5">
                    <div class="col-12 col-md-6">
                        <div>
                            <div
                                class="d-flex align-items-start justify-content-start gap-3"
                            >
                                <h4>Loyalty Points</h4>
                                <img
                                    src="@/assets/img/Royalty.png"
                                    alt=""
                                    style="width: 2rem;"
                                />
                            </div>
                            <p style="font-size:0.8rem">
                                Pyae Sone Shin App/Web
                                ကနေတစ်ဆင့်ကိုယ်ကြိုက်တဲ့ရတနာတစ်မျိုးကိုဝယ်ယူရုံနဲ့
                                Royalty Point ကိုရရှိမှာဖြစ်ပါတယ်။ Royalty Point
                                ရဲ့သက်တမ်းကတော့ (၃)လဖြစ်ပါတယ်။
                            </p>

                            <div
                                class="d-flex align-items-start justify-content-start gap-3"
                            >
                                <h4>Mining Points</h4>
                                <img
                                    src="@/assets/img/Mining.png"
                                    alt=""
                                    style="width: 2rem;"
                                />
                            </div>
                            <p style="font-size:0.8rem">
                                Mining Point ဆိုတာကတော့ ပထမဆုံး Sign up
                                လုပ်တဲ့အချိန်ကစပြီး နေ့စဉ် Log in ဝင်ခြင်း
                                Product Review ပေးခြင်း Vlog Post များ
                                ဝင်ရောက်ဖတ်ရှုခြင်း ၊ Comment ပေးခြင်း Jewel
                                Selfie နဲ့ရိုက်ထားတဲ့ပုံတွေကိုShare ပေးခြင်းစတဲ့
                                Activities တွေလုပ်ပေးရုံနဲ့
                                နေ့စဉ်အများဆုံး(300)pointsအထိ
                                ရရှိနိုင််မှာဖြစ်ပါတယ်။ Mining Point
                                ရဲ့သက်တမ်းကတော့ (၆)လဖြစ်ပါတယ်။
                            </p>

                            <div
                                class="d-flex align-items-start justify-content-start gap-3"
                            >
                                <h4>Referral Points</h4>
                                <img
                                    src="@/assets/img/Referal1.png"
                                    alt=""
                                    style="width: 2rem;"
                                />
                            </div>
                            <p style="font-size:0.8rem">
                                Pyae Sone Shin Web/App လေးကို
                                ကိုယ်တိုင်အသုံးပြုရုံတင်မဟုတ်ဘဲ သူငယ်ချင်းတွေကို
                                Share ပေးရုံနဲ့လည်း Referral Point ဆိုတာကို
                                ရရှိဦးမှာဖြစ်ပါတယ်။
                            </p>
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <h6 class="fw-bold">မေးနေကျမေးခွန်းများ</h6>
                        <div id="accordion" role="tablist" class="my-3">
                            <div class="card" style="border:none;">
                                <div
                                    class="card-header bg-white"
                                    style="padding: 0.5rem 0rem"
                                    role="tab"
                                    id="headingOne"
                                >
                                    <p
                                        data-bs-toggle="collapse"
                                        href="#collapseOne"
                                        aria-expanded="true"
                                        aria-controls="collapseOne"
                                        class="cursor m-0 fw-bolder"
                                    >
                                        Mining Point/Royalty Point နဲ့ Referral
                                        Point တွေကို
                                        တပြိုင်နက်ထဲလဲလှယ်ခွင့်ရှိလား ❓
                                    </p>

                                    <div
                                        id="collapseOne"
                                        class="collapse"
                                        role="tabpanel"
                                        aria-labelledby="headingOne"
                                        data-parent="#accordion"
                                    >
                                        <p class="m-0">
                                            Point တွေကို တပြိုင်နက်ထဲတော့
                                            လဲလှယ်ခွင့်မရှိပါဘူး
                                        </p>
                                    </div>
                                </div>

                                <div
                                    class="card-header bg-white"
                                    style="padding: 1rem 0rem"
                                    role="tab"
                                    id="headingTwo"
                                >
                                    <p
                                        data-bs-toggle="collapse"
                                        href="#collapseTwo"
                                        aria-expanded="true"
                                        aria-controls="collapseTwo"
                                        class="cursor m-0 fw-bolder"
                                    >
                                        How To get Mining Point ❓
                                    </p>
                                    <div
                                        id="collapseTwo"
                                        class="collapse"
                                        role="tabpanel"
                                        aria-labelledby="headingTwo"
                                        data-parent="#accordion"
                                    >
                                        <p class="m-0">
                                            Mining Point
                                            ကိုဘယ်လိုရရှိနိုင်မလဲဆိုတော့ နေ့စဥ်
                                            Log in ဝင်ခြင်း Product Review
                                            ပေးခြင်း၊ Vlog Post များ ဝင်ရောက်
                                            ဖတ်ရှုခြင်း ၊ Comment ပေးခြင်း
                                            ရတနာပုံတွေကို Wishlist အသဲပေးခြင်းFb
                                            share ခြင်းဖြင့် Mining Point
                                            တွေကိုရရှိမှာဖြစ်ပါတယ်။
                                        </p>
                                    </div>
                                </div>

                                <div
                                    class="card-header bg-white"
                                    style="padding: 1rem 0rem"
                                    role="tab"
                                    id="headingThree"
                                >
                                    <p
                                        data-bs-toggle="collapse"
                                        href="#collapseThree"
                                        aria-expanded="true"
                                        aria-controls="collapseThree"
                                        class="cursor m-0 fw-bolder"
                                    >
                                        How To get Royalty Point ❓
                                    </p>
                                    <div
                                        id="collapseThree"
                                        class="collapse"
                                        role="tabpanel"
                                        aria-labelledby="headingThree"
                                        data-parent="#accordion"
                                    >
                                        <p class="m-0">
                                            PSS ရဲ့ Mobile App / Website ကတဆင့်
                                            ရတနာပစ္စည်းတစ်ခု ဝယ်ယူတိုင်း Royalty
                                            Point တွေကိုရရှိမှာဖြစ်ပါတယ်။
                                            Royalty Point ကိုတော့ Discount
                                            (သို့) Cashback အနေနဲ့
                                            အသုံးပြုနိုင်မှာဖြစ်ပြီး Mining
                                            Pointကိုတော့ အရေအတွက်‌
                                            အနည်းအများပေါ် မူတည်ပြီး
                                            ဆုလက်ဆောင်တွေနဲ့
                                            လဲလှယ်နိုင်မှာဖြစ်ပါတယ်။
                                        </p>
                                    </div>
                                </div>

                                <div
                                    class="card-header bg-white"
                                    style="padding: 1rem 0rem"
                                    role="tab"
                                    id="headingFour"
                                >
                                    <p
                                        data-bs-toggle="collapse"
                                        href="#collapseFour"
                                        aria-expanded="true"
                                        aria-controls="collapseFour"
                                        class="cursor m-0 fw-bolder"
                                    >
                                        How To get Referral Points ❓
                                    </p>
                                    <div
                                        id="collapseFour"
                                        class="collapse"
                                        role="tabpanel"
                                        aria-labelledby="headingFour"
                                        data-parent="#accordion"
                                    >
                                        <p class="m-0">
                                            App နဲ့ Website ကို
                                            တဆင့်ပြန်လည်ညွှန်းဆိုပေးသည့်သူတိုင်းလည်း
                                            Referral Points တွေ ရရှိဦးမှာ
                                            ဖြစ်ပါတယ်။
                                        </p>
                                    </div>
                                </div>
                                <div
                                    class="card-header bg-white"
                                    style="padding: 1rem 0rem"
                                    role="tab"
                                    id="headingFive"
                                >
                                    <p
                                        data-bs-toggle="collapse"
                                        href="#collapseFive"
                                        aria-expanded="true"
                                        aria-controls="collapseFive"
                                        class="cursor m-0 fw-bolder"
                                    >
                                        Point တွေကလဲလှယ်လို့ရလား ❓
                                    </p>
                                    <div
                                        id="collapseFive"
                                        class="collapse"
                                        role="tabpanel"
                                        aria-labelledby="headingFive"
                                        data-parent="#accordion"
                                    >
                                        <p class="m-0">
                                            Mining Point ကို Royalty Point
                                            နဲ့လည်းလဲလှယ်နိုင်မှာ ဖြစ်ပါတယ်။
                                        </p>
                                    </div>
                                </div>
                                <div
                                    class="card-header bg-white"
                                    style="padding: 1rem 0rem"
                                    role="tab"
                                    id="headingSix"
                                >
                                    <p
                                        data-bs-toggle="collapse"
                                        href="#collapseSix"
                                        aria-expanded="true"
                                        aria-controls="collapseSix"
                                        class="cursor m-0 fw-bolder"
                                    >
                                        ရမှတ်ပွိုင့်တွေကိုဘယ်လိုပြန်အသုံးပြုလို့ရမလဲ
                                        ❓
                                    </p>
                                    <div
                                        id="collapseSix"
                                        class="collapse"
                                        role="tabpanel"
                                        aria-labelledby="headingSix"
                                        data-parent="#accordion"
                                    >
                                        <p class="m-0">
                                            ရရှိတဲ့ point များကို Discount
                                            (သို့) Cash Back အနေနဲ့ ပြန်လည်
                                            လဲလှယ်နိုင်မှာဖြစ်ပါတယ်။
                                        </p>
                                    </div>
                                </div>
                                <div
                                    class="card-header bg-white"
                                    style="padding: 1rem 0rem"
                                    role="tab"
                                    id="headingSeven"
                                >
                                    <p
                                        data-bs-toggle="collapse"
                                        href="#collapseSeven"
                                        aria-expanded="true"
                                        aria-controls="collapseSeven"
                                        class="cursor m-0 fw-bolder"
                                    >
                                        Leaderboard မှာ ပါရင်
                                        ဘာအကျိုးကျေးဇူးရနိုင်လဲ❓
                                    </p>
                                    <div
                                        id="collapseSeven"
                                        class="collapse"
                                        role="tabpanel"
                                        aria-labelledby="headingSeven"
                                        data-parent="#accordion"
                                    >
                                        <p class="m-0">
                                            Leader Board မွာ သင်က Top Winner
                                            List ထဲပါခဲ့ရင်တော့
                                            နှစ်စဉ်တန်ဖိုးကြီးဆုလက်ဆောင်တွေကို
                                            လက်ဝယ်ပိုင်ဆိုင်နိုင်မှာဖြစ်ပါတယ်။
                                        </p>
                                    </div>
                                </div>
                                <div
                                    class="card-header bg-white"
                                    style="padding: 1rem 0rem"
                                    role="tab"
                                    id="headingEight"
                                >
                                    <p
                                        data-bs-toggle="collapse"
                                        href="#collapseEight"
                                        aria-expanded="true"
                                        aria-controls="collapseEight"
                                        class="cursor m-0 fw-bolder"
                                    >
                                        Points သုံးခုလုံး စုဆောင်းမယ့်သူတွေအတွက်
                                        အကြီးမားဆုံးရရှိမယ့် အခွင့်အရေးရောရှိလား
                                        ❓
                                    </p>
                                    <div
                                        id="collapseEight"
                                        class="collapse"
                                        role="tabpanel"
                                        aria-labelledby="headingEight"
                                        data-parent="#accordion"
                                    >
                                        <p class="m-0">
                                            Points စုဆောင်းသူတွေအတွက် နောက်ထပ်
                                            Features တစ်ခုကတော့ နေ့စဉ်
                                            Points(၃)မျိုးစလုံးမှာ
                                            အများဆုံးစုဆောင်းရရှိထားတဲ့ Winner
                                            တွေကိုဖော်ပြပေးမယ့် Leader Board
                                            Feature လည်းပါဝင်မှာဖြစ်ပါတယ်။
                                        </p>
                                    </div>
                                </div>
                                <div
                                    class="card-header bg-white"
                                    style="padding: 1rem 0rem"
                                    role="tab"
                                    id="headingNine"
                                >
                                    <p
                                        data-bs-toggle="collapse"
                                        href="#collapseNine"
                                        aria-expanded="true"
                                        aria-controls="collapseNine"
                                        class="cursor m-0 fw-bolder"
                                    >
                                        PSS ရဲ့ Mobile App ကနေ Gold
                                        (ရွှေ)ဝယ်ယူရင်ရော ဘယ်လို Point
                                        တွေခံစားရမှာလဲ
                                    </p>
                                    <div
                                        id="collapseNine"
                                        class="collapse"
                                        role="tabpanel"
                                        aria-labelledby="headingNine"
                                        data-parent="#accordion"
                                    >
                                        <p class="m-0">
                                            PSSရဲ့ Mobile App တွေကနေ Gold
                                            ဝယ်ယူသူတိုင်း Gold Point
                                            တွေရရှိမှာဖြစ်ပြီး အကယ်၍ သင်က
                                            Diamond ဝယ်ယူမယ်ဆိုရင်တော့ Diamond
                                            Point တွေရရှိမှာဖြစ်ပါတယ်။
                                        </p>
                                    </div>
                                </div>
                                <div
                                    class="card-header bg-white"
                                    style="padding: 1rem 0rem"
                                    role="tab"
                                    id="headingTen"
                                >
                                    <p
                                        data-bs-toggle="collapse"
                                        href="#collapseTen"
                                        aria-expanded="true"
                                        aria-controls="collapseTen"
                                        class="cursor m-0 fw-bolder"
                                    >
                                        18K/999 အခေါက်ရွှေ ,Gold Point နဲ့
                                        Diamond Point ကိုရော အလဲလှယ်လုပ်လို့ရလား
                                        ❓
                                    </p>
                                    <div
                                        id="collapseTen"
                                        class="collapse"
                                        role="tabpanel"
                                        aria-labelledby="headingTen"
                                        data-parent="#accordion"
                                    >
                                        <p class="m-0">
                                            Gold Point ကို Diamond Point နဲ့
                                            လဲလှယ်ခွင့်ရှိပါတယ် ဒါပေမယ့် Diamond
                                            Point ကိုတော့ Gold Point ,.18K/999
                                            အခေါက်ရွှေနဲ့ လဲလှယ်ခွင့်မရှိပါဘူး
                                        </p>
                                    </div>
                                </div>
                                <div
                                    class="card-header bg-white"
                                    style="padding: 1rem 0rem"
                                    role="tab"
                                    id="headingEleven"
                                >
                                    <p
                                        data-bs-toggle="collapse"
                                        href="#collapseEleven"
                                        aria-expanded="true"
                                        aria-controls="collapseEleven"
                                        class="cursor m-0 fw-bolder"
                                    >
                                        ပွိုင့်တွေကအကြာကြီးမသုံးဘဲ ပစ်ထားမိရင်
                                        Expire ဖြစ်တတ်လား ❓
                                    </p>
                                    <div
                                        id="collapseEleven"
                                        class="collapse"
                                        role="tabpanel"
                                        aria-labelledby="headingEleven"
                                        data-parent="#accordion"
                                    >
                                        <p class="m-0">
                                            PSS Mobile App/ Website တွင်
                                            အကောင့်ဖွင့်ပြီးနေ့မှစပြီး (၆)
                                            လအတွင်း အသုံးပြုခြင်းမရှိပါက Point
                                            များ အလိုအလျောက်
                                            ပျက်ပြယ်သွားမှာဖြစ်ပါတယ်။
                                        </p>
                                    </div>
                                </div>
                                <div
                                    class="card-header bg-white"
                                    style="padding: 1rem 0rem"
                                    role="tab"
                                    id="headingTwelve"
                                >
                                    <p
                                        data-bs-toggle="collapse"
                                        href="#collapseTwelve"
                                        aria-expanded="true"
                                        aria-controls="collapseTwelve"
                                        class="cursor m-0 fw-bolder"
                                    >
                                        ပွိုင့်တွေအများကြီးရအောင်
                                        ဘယ်လိုတွေစုဆောင်းသင့်လဲ❓
                                    </p>
                                    <div
                                        id="collapseTwelve"
                                        class="collapse"
                                        role="tabpanel"
                                        aria-labelledby="headingTwelve"
                                        data-parent="#accordion"
                                    >
                                        <p class="m-0">
                                            ပထမဆုံး Sign up လုပ်တဲ့အချိန်ကစပြီး
                                            နေ့စဉ် Log in ဝင်ခြင်း Product
                                            Review ပေးခြင်း ရတနာပုံတွေကို
                                            Wishlist အသဲပေးခြင်း၊ Vlog
                                            Post,Jewel Selfie
                                            နဲ့ရိုက်ထားတဲ့ပုံတွေကိုShare
                                            ပေးခြင်းစတဲ့ Activities
                                            တွေလုပ်ပေးရုံနဲ့ Mining Point
                                            နေ့စဉ်အများဆုံး(300)pointsအထိ
                                            ရရှိမှာဖြစ်ပါတယ်။
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- points history -->
                <div class="py-5">
                    <div
                        class="d-flex flex-column align-items-center"
                        v-if="isLoggedIn"
                    >
                        <h3 class="fw-bold" style="color: #855E0A;">
                            My Tickets
                        </h3>
                        <div class="redeem-history">
                            <span class="tickets-history"
                                >Ticket/Points History</span
                            >
                            <div
                                v-if="redeemTicketsArray.length > 0"
                                class="d-flex overflow-auto"
                            >
                                <div
                                    class="
                                    discount-ticket
                                    d-flex
                                    align-items-start
                                    gap-1
                                    flex-nowrap
                                "
                                    v-for="(redeem,
                                    index) in redeemTicketsArray"
                                    :key="index"
                                >
                                    <div
                                        style="width: 14rem; height:130px"
                                        class="d-flex"
                                    >
                                        <div class="content" style="width:25%;">
                                            <div
                                                class="d-flex flex-column align-items-center text-white"
                                                style="margin-top:3rem"
                                            >
                                                <font-awesome-icon
                                                    v-if="
                                                        redeem.redeem
                                                            .point_type ==
                                                            'shwepyae'
                                                    "
                                                    :icon="['fas', 'crown']"
                                                />
                                                <font-awesome-icon
                                                    v-if="
                                                        redeem.redeem
                                                            .point_type ==
                                                            'ngwepyae'
                                                    "
                                                    :icon="['far', 'gem']"
                                                    style="transform: scaleY(-1);"
                                                />
                                                <div
                                                    v-if="
                                                        redeem.redeem
                                                            .point_type ==
                                                            'referral'
                                                    "
                                                    class="octagon"
                                                ></div>
                                                <span>{{
                                                    redeem.redeem.point
                                                }}</span>
                                            </div>
                                        </div>
                                        <div
                                            style="width: 70%;border: 1px solid #0000000D;background:#FFFFFF"
                                            class="position-relative"
                                        >
                                            <div
                                                class="d-flex flex-column justify-content-between"
                                            >
                                                <div class="ms-1 pt-1">
                                                    <p
                                                        style="font-size:13px; margin:0; padding:0"
                                                    >
                                                        {{ redeem.redeem.name }}
                                                    </p>
                                                    <p
                                                        class="fw-bold"
                                                        style="font-size:16px; margin:0; padding:0"
                                                    >
                                                        {{
                                                            redeem.redeem.price
                                                        }}
                                                        MMK
                                                    </p>
                                                    <p
                                                        style="font-size:13px; margin:0; padding:0"
                                                    >
                                                        Code - {{ redeem.code }}
                                                    </p>
                                                    <p
                                                        class="redeem-card-btn"
                                                        style="font-size: 12px"
                                                    >
                                                        <span
                                                            v-if="
                                                                isUsing(
                                                                    redeem.code,
                                                                )
                                                            "
                                                            class="useRedeem"
                                                            >Selected</span
                                                        >
                                                        <span
                                                            v-else
                                                            class="useRedeem"
                                                            @click="
                                                                useRedeem(
                                                                    redeem,
                                                                )
                                                            "
                                                            >Use now</span
                                                        >
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <available-reward
                    point-name="Loyalty Points"
                    class="mb-5"
                    :coupons="shwePyaeRewards"
                />
                <available-reward
                    point-name="Mining Points"
                    class="mb-5"
                    :coupons="ngwePyaeRewards"
                />
                <available-reward
                    point-name="Refer Points"
                    :coupons="referralRewards"
                />
            </div>
            <div class="col-12 col-md-6 col-lg-4 col-xl-3 mt-3">
                <div class="px-1 py-3 leaderboard">
                    <!-- leaderboard type -->
                    <point-leaderboard
                        :loyalityData="loyalityData"
                        :miningData="miningData"
                        :referalData="referalData"
                        :showOnHome="false"
                    />
                </div>

                <button class="point-button w-100" @click="toBlogPage">
                    Get
                    <span class="fw-bold" style="margin-right:1rem"
                        >50 Mining points free</span
                    ><font-awesome-icon :icon="['fas', 'chevron-right']" />
                </button>
            </div>
        </div>
    </div>
</template>

<script>
// import Button from '../components/reusable/Button';
import AvailableReward from '../components/points/AvailableReward';
import { mapGetters } from 'vuex';
import LeaderBoardAllGql from '@/graphql/queries/point/LeaderBoardAll.gql';
import PointLeaderBoard from '../components/points/PointLeaderBoard.vue';
import RedeemListGql from '@/graphql/queries/point/RedeemList.gql';
import RewardHistory from '../mixins/RedeemHistory.js';
import CheckRedeem from '@/graphql/mutations/order/CheckRedeem.gql';
import BannerSlide from '@/graphql/queries/home/BannerSlide.gql';
import Button from '../components/reusable/Button.vue';

export default {
    mixins: [RewardHistory],
    components: {
        // 'main-button': Button,
        'available-reward': AvailableReward,
        'point-leaderboard': PointLeaderBoard,
        'main-button': Button,
    },

    data() {
        return {
            loyalty_coupons: [1, 2, 3, 4, 5],
            mining_coupons: [1, 2, 3, 4],
            refer_coupons: [1, 2],
            loyalityData: [],
            miningData: [],
            referalData: [],
            bannerThree: null,
        };
    },
    computed: {
        ...mapGetters({
            user: 'auth/getUser',
            isLoggedIn: 'auth/isLoggedIn',
            selectedRedeem: 'home/getSelectedRedeem',
            shwePyaeRewards: 'point/getShwePyaeRewardList',
            ngwePyaeRewards: 'point/getNgwePyaeRewardList',
            referralRewards: 'point/getReferralRewardList',
            redeemLoyalHistories: 'point/getRedeemLoyalHistories',
            redeemReferralHistories: 'point/getRedeemActivityHistories',
            redeemActivityHistories: 'point/getRedeemReferralHistories',
        }),
        redeemTicketsArray() {
            return this.redeemLoyalHistories.concat(
                this.redeemReferralHistories,
                this.redeemActivityHistories,
            );
        },
    },

    mounted() {
        this.$on('reloadHistory', () => {
            this.getRedeemHistory();
        });
        this.getArrangeBanners();
        this.getRedeemHistory();
        this.getShweRedeemList();
        this.getNgweRedeemList();
        this.getReferalRedeemList();
        this.getLeaderBoardShweOffline();
        this.getLeaderBoardNgweOffline();
        this.getLeaderBoardReferOffline();
    },

    watch: {
        user() {
            if (this.user && this.user.id) {
                this.getLeaderBoardShweOffline();
                this.getLeaderBoardNgweOffline();
                this.getLeaderBoardReferOffline();
            }
        },
    },

    methods: {
        isUsing(code) {
            return this.selectedRedeem && this.selectedRedeem.code === code;
        },
        getArrangeBanners() {
            this.$apollo
                .query({
                    query: BannerSlide,
                })
                .then(response => {
                    // console.log('arrangeone', response.data.webbanners[3]);
                    let bannerArray = response.data.webbanners;
                    bannerArray.map(banner => {
                        if (banner.arrange === 3) {
                            this.bannerThree = banner;
                        }
                    });
                })
                .catch(error => {
                    console.log(error);
                });
        },
        getLeaderBoardShweOffline() {
            this.$apollo
                .query({
                    query: LeaderBoardAllGql,

                    variables: {
                        field_name: 'product_point',
                    },
                })
                .then(response => {
                    // console.log(this.user, 'user');
                    this.loyalityData = response.data.leaderboardsall;
                })
                .catch(error => {
                    console.log(error);
                });
        },

        getLeaderBoardNgweOffline() {
            this.$apollo
                .query({
                    query: LeaderBoardAllGql,

                    variables: {
                        field_name: 'point',
                    },
                })
                .then(response => {
                    this.miningData = response.data.leaderboardsall;
                })
                .catch(error => {
                    console.log(error);
                });
        },

        getLeaderBoardReferOffline() {
            this.$apollo
                .query({
                    query: LeaderBoardAllGql,

                    variables: {
                        field_name: 'referral_point',
                    },
                })
                .then(response => {
                    // console.log(this.user, 'user');
                    this.referalData = response.data.leaderboardsall;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        getShweRedeemList() {
            this.$apollo
                .query({
                    query: RedeemListGql,
                    fetchPolicy: 'no-cache',
                    variables: {
                        point_type: 'shwepyae',
                    },
                })
                .then(response => {
                    this.$store.commit(
                        'point/ADD_SHWE_PYAE_REWARDS',
                        response.data.redeemCardList,
                    );
                })
                .catch(error => {
                    console.log(error);
                });
        },
        getNgweRedeemList() {
            this.$apollo
                .query({
                    query: RedeemListGql,
                    fetchPolicy: 'no-cache',
                    variables: {
                        point_type: 'ngwepyae',
                    },
                })
                .then(response => {
                    this.$store.commit(
                        'point/ADD_NGWE_PYAE_REWARDS',
                        response.data.redeemCardList,
                    );
                })
                .catch(error => {
                    console.log(error);
                });
        },
        getReferalRedeemList() {
            this.$apollo
                .query({
                    query: RedeemListGql,
                    fetchPolicy: 'no-cache',
                    variables: {
                        point_type: 'referral',
                    },
                })
                .then(response => {
                    this.$store.commit(
                        'point/ADD_REFERRAL_REWARDS',
                        response.data.redeemCardList,
                    );
                })
                .catch(error => {
                    console.log(error);
                });
        },
        useRedeem(redeemInput) {
            this.$apollo
                .mutate({
                    mutation: CheckRedeem,

                    variables: {
                        user_id: this.user.id,
                        code: redeemInput.code,
                    },
                })
                .then(response => {
                    const redeem = response.data.checkRedeemCode;
                    if (redeem.status == 'success') {
                        let selectRedeem = {
                            ...redeem,
                            code: redeemInput.code,
                            point: redeemInput.redeem.point,
                            point_type: redeemInput.redeem.point_type,
                        };
                        this.$store.commit(
                            'home/ADD_SELECTED_REDEEM',
                            selectRedeem,
                        );
                    } else {
                        alert(redeem.message);
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        expireDays(date) {
            if (date != null) {
                const expireDate = date.split(' ')[0];
                const expireYear = expireDate.split('-')[0];
                const expireMonth = expireDate.split('-')[1];
                const expireDay = expireDate.split('-')[2];
                let date_1 = new Date(
                    `${expireMonth}/${expireDay}/${expireYear}`,
                );
                let date_2 = new Date();

                const days = (date_1, date_2) => {
                    let difference = date_1.getTime() - date_2.getTime();
                    let TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
                    return TotalDays;
                };
                return days(date_1, date_2);
            } else {
                return '-';
            }
        },
        toBlogPage() {
            this.$router.push({
                name: 'Blog',
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.container {
    .leaderboard {
        // position: fixed;
        background: transparent
            linear-gradient(180deg, #fffddd00 0%, #fffdddbf 100%) 0% 0%
            no-repeat padding-box;
        border: 1px solid #efd59c;
        border-radius: 10px;
        opacity: 1;
        top: 1rem;
    }
    .leaderboard-header {
        border-bottom: solid 1px #efd59c;
    }
    .border-bottom-color {
        margin-bottom: -2px;
        border-bottom: 2px solid #855e0a;
        position: relative;
        &::after {
            border-right: solid 7px transparent;
            border-left: solid 7px transparent;
            border-top: solid 7px #855e0a;
            transform: translateX(-50%);
            position: absolute;
            z-index: 1;
            content: '';
            top: 100%;
            left: 50%;
            height: 0;
            width: 0;
        }
    }
    .point-button {
        color: #fff;
        background: transparent
            linear-gradient(99deg, #cfff9a 0%, #57b20f 23%, #399410 100%) 0% 0%
            no-repeat padding-box;
        border: 1px solid #0000001a;
        border-radius: 23px;
        opacity: 1;
        margin: 3rem 0;
        padding: 0.5rem;
    }
    .text {
        padding: 3px 5px;
        top: 30px;
        left: 30px;
    }
    .redeem-history {
        position: relative;
        border: 1px dashed #855e0a;
        background: transparent
            linear-gradient(106deg, #fffddd00 0%, #fffdddbf 100%) 0% 0%
            no-repeat padding-box;
        border: 1px dashed #855e0a;
        border-radius: 10px;
        opacity: 1;
        height: auto;
        width: 100%;
        height: 150px;
        padding: 0.5rem;
        @media (min-width: 1189.98px) {
            width: 70%;
        }
    }
}
[data-bs-toggle='collapse']:after {
    content: ' ';
    display: inline-block;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    border-bottom: 3px solid #333;
    border-right: 3px solid #333;
    height: 10px;
    width: 10px;
    position: absolute;
    right: 1rem;
    margin: 0.5rem 0 0 0;
    transform: rotate(-45deg);
    transition: all linear 0.25s;
}
// [data-bs-toggle='collapse'].collapsed:after {
//     transform: rotate(45deg);
// }
// .discount-ticket {
//     width: 220px;
//     height: 54px;
//     background: #ffffff 0% 0% no-repeat padding-box;
//     border: 1px solid #0000000d;
//     border-radius: 10px;
//     opacity: 1;
//     margin: 0.5rem 0;
//     .coupon {
//         width: 48px;
//         height: 54px;
//         background: #466be5 0% 0% no-repeat padding-box;
//         border-radius: 10px 0px 0px 10px;
//         opacity: 1;
//     }
// }
.tickets-history {
    float: right;
    margin-top: -2.5rem;
    color: #855e0a;
    @media (max-width: 576px) {
        position: absolute;
        top: -10%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #855e0a;
    }
}
.redeem-card-btn {
    background: #e4a825 0% 0% no-repeat padding-box;
    border-radius: 18px;
    opacity: 1;
    color: #fff;
    border-style: none;
    position: absolute;
    // left: 41%;
    bottom: 5px;
    right: 5px;
    font-size: 12px;
}
.useRedeem {
    background: #e4a825 0% 0% no-repeat padding-box;
    border-radius: 18px;
    opacity: 1;
    color: #fff;
    padding: 0 0.2rem;
    cursor: pointer;
    border-style: none;
}
.content {
    width: 65px;
    height: 100%;
    -webkit-mask: radial-gradient(circle at top, transparent 10px, red 0),
        radial-gradient(circle at bottom, transparent 10px, red 0);
    -webkit-mask-composite: source-in;
    background: #e4a825 0% 0% no-repeat padding-box;
}
// .leaderboard-position {
//     position: relative;
//     @media (min-width: 992px) {
//         position: fixed !important;
//     }
// }
</style>
